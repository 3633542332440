@import "~swiper/css/bundle";

/* Customize */
:root {
  --swiper-navigation-color: #fff;
  --swiper-navigation-size: 1rem;
  --swiper-pagination-bullet-size: 0.75rem;
  --swiper-pagination-color: #333;
  --swiper-preloader-color: #ddd;
}

/* Navigation */
.swiper-button-next,
.swiper-button-prev {
  --nav-button-size: calc(var(--swiper-navigation-size) + 2em);
  background: rgb(33, 33, 33, 60%);
  border-radius: 50%;
  width: var(--nav-button-size);
  height: var(--nav-button-size);
  transform: translateY(-25%);
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-weight: 700;
  transform: translateX(var(--nav-button-centering-size));
}
.swiper-button-next::after {
  --nav-button-centering-size: 1px;
}
.swiper-button-prev::after {
  --nav-button-centering-size: -1px;
}

/* Thumbs */
.swiper-slide-thumb-custom-border {
  border: var(--swiper-thumbs-custom-border-size, 2px) solid transparent;
  cursor: pointer;
}
.swiper-slide-thumb-custom-border.swiper-slide-thumb-active {
  border-color: var(--swiper-thumbs-custom-border-color, #cab270);
}

/* Zoom */
.swiper-slide:not(.swiper-slide-zoomed) .swiper-zoom-container img {
  cursor: zoom-in;
}
.swiper-custom-zoomable img {
  cursor: zoom-in;
}
